import React from 'react'

const LeavingPage: React.FC = () => {
  return (
    <div className="column-center">
      <h3>Leaving</h3>
    </div>
  )
}

export default LeavingPage
