import React, { useEffect } from 'react'
import { ZoomMtg } from '@zoom/meetingsdk';
import { useAxios } from '../utils'
import { config, MeetingConfig, ZoomRoleEnum } from '../types'
import { useLocation } from 'react-router-dom'

// ZoomMtg.setZoomJSLib('https://source.zoom.us/2.7.0/lib', '/av')

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();

// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load('pt-PT')
ZoomMtg.i18n.reload('pt-PT')

// pass in the registrant's token if your meeting or webinar requires registration. More info here:
// Meetings: https://marketplace.zoom.us/docs/sdk/native-sdks/web/build/meetings/join#join-registered
// Webinars: https://marketplace.zoom.us/docs/sdk/native-sdks/web/build/webinars/join#join-registered-webinar
const registrantToken = ''

function beginJoin(meetingConfig: MeetingConfig) {
  console.log('meetingConfig->', meetingConfig)

  const $elZoom = document.getElementById('zmmtg-root')
  if ($elZoom) {
    $elZoom.style.display = 'block'
  }

  ZoomMtg.init({
    leaveUrl: meetingConfig.leaveUrl,
    patchJsMedia: true,
    leaveOnPageUnload: true,
    success: function (suc: any) {
      console.log('ZoomMtg.init.success', suc)

      ZoomMtg.i18n.load(meetingConfig.lang)
      ZoomMtg.i18n.reload(meetingConfig.lang)

      ZoomMtg.join({
        meetingNumber: meetingConfig.meetingNumber,
        userName: meetingConfig.userName,
        sdkKey: meetingConfig.apiKey,
        signature: meetingConfig.signature,
        userEmail: meetingConfig.userEmail,
        passWord: meetingConfig.passWord,
        tk: registrantToken,
        success: function (res: any) {
          console.log('join meeting success', res)
          // console.log("get attendeelist");
          // ZoomMtg.getAttendeeslist({});
          // ZoomMtg.getCurrentUser({
          //   success: function (res: any) {
          //     console.log("success getCurrentUser", res.result.currentUser);
          //   },
          // });
        },
        error: function (res: any) {
          console.error('ZoomMtg.join.error', res)
        }
      })
    },
    error: function (res: any) {
      console.error('ZoomMtg.init.error', res)
    }
  })

  ZoomMtg.inMeetingServiceListener('onUserJoin', (data: any) => {
    console.log('inMeetingServiceListener onUserJoin', data)
  })

  ZoomMtg.inMeetingServiceListener('onUserLeave', (data: any) => {
    console.log('inMeetingServiceListener onUserLeave', data)
  })

  ZoomMtg.inMeetingServiceListener('onUserIsInWaitingRoom', (data: any) => {
    console.log('inMeetingServiceListener onUserIsInWaitingRoom', data)
  })

  ZoomMtg.inMeetingServiceListener('onMeetingStatus', (data: any) => {
    console.log('inMeetingServiceListener onMeetingStatus', data)
  })
}

const MeetingPage: React.FC = () => {
  const location = useLocation()
  const axiosInstance = useAxios(config.uri)

  const search = new URLSearchParams(location.search)

  const input = {
    meetingNumber: search.get('mn') || window.localStorage.getItem('ZOOM_MN'),
    passWord: search.get('pwd') || window.localStorage.getItem('ZOOM_PWD')
  }

  window.localStorage.setItem('ZOOM_MN', input.meetingNumber as string)
  window.localStorage.setItem('ZOOM_PWD', input.passWord as string)

  console.log('MeetingPage: input->', input)

  const meetingConfig: Partial<MeetingConfig> = {
    leaveUrl: `${window.location.origin}/leaving.html`,
    role: ZoomRoleEnum.Attendee,
    lang: 'pt-PT',
    china: false
  }

  useEffect(() => {
    if (axiosInstance.current) {
      axiosInstance.current
        .post('/api/zoom/signature', input)
        .then(({ data }: any) => {
          console.log('response-api', data)
          beginJoin(Object.assign({}, meetingConfig, data.config))
        })
    }
  }, [axiosInstance])

  return <div>Zoom Meeting</div>
}

export default MeetingPage
