type Config = {
  uri: string
  keycloak: string
}

// const local: Config = {
//   uri: 'http://localhost:3100',
//   keycloak: '/keycloak-local.json'
// }

const production: Config = {
  uri: 'https://services.votepraticasinnovare.com.br',
  keycloak: '/keycloak.json'
}

export const config = production
