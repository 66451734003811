import React from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { KeycloakInstance } from 'keycloak-js'
import { HashRouter as Router, Redirect, Route } from 'react-router-dom'
import { LeavingPage, MeetingPage } from '../pages'

export const AppRouter: React.FC = () => {
  const { keycloak, initialized } = useKeycloak<KeycloakInstance>()

  if (!initialized) {
    return <div>Carregando...</div>
  }

  if (!keycloak?.authenticated) {
    return <div>Não autenticado!</div>
  }

  return (
    <Router>
      <Redirect from="/" to="/meeting" />
      <Route path="/meeting" exact component={MeetingPage} />
      <Route path="/leaving" exact component={LeavingPage} />
    </Router>
  )
}
