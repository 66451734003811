import React from 'react'
import { ReactKeycloakProvider } from '@react-keycloak/web'

import { AppRouter } from './routes'

import keycloak, {
  keycloakEventLogger,
  keycloakInitConfig,
  keycloakTokenLogger
} from './providers/keycloak'

const App: React.FC = () => {
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={keycloakInitConfig}
      onEvent={keycloakEventLogger}
      onTokens={keycloakTokenLogger}
    >
      <AppRouter />
    </ReactKeycloakProvider>
  )
}

export default App
