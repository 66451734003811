export enum ZoomRoleEnum {
  Attendee = 0
}

export interface MeetingConfig {
  apiKey: string
  meetingNumber: string
  userName: string
  passWord: string
  leaveUrl: string
  role: ZoomRoleEnum
  userEmail: string
  lang: 'pt-PT'
  signature: string
  china: boolean
  webEndpoint?: string
}
